import styled from 'styled-components';
import { breakpoint, color } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const SocialLinksContainer = styled.ul<{ theme: Theme }>`
  border-bottom: 1px solid ${color.secondaryLightGray};
  display: inline-flex;
  flex-direction: row;
  padding: 0px 4px 4px 4px;

  @media (${breakpoint.mdMin}) {
    border-bottom: none;
    border-right: 1px solid ${color.secondaryLightGray};
    flex-direction: column;
    padding: 4px 8px 4px 0px;
    position: sticky;
    right: 0;
    top: 125px;
  }
`;

export const SocialLinkContainer = styled.li<{ theme: Theme }>`
  margin-right: 16px;

  @media (${breakpoint.mdMin}) {
    margin-right: 0px;
    margin-bottom: 16px;
  }

  svg {
    height: 18px;
    width: 18px;
    fill: ${getColor('iconSecondary')};
  }

  &:last-child {
    margin-right: 0px;

    @media (${breakpoint.mdMin}) {
      margin-bottom: 0px;
    }
  }

  a:hover {
    svg {
      cursor: pointer;
      fill: ${getColor('iconAccentPrimary')};
    }
  }

  a:active, a:focus {
    svg {
      fill: ${getColor('iconTertiary')};
    }
  }
`;
