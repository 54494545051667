import styled from 'styled-components';

const SpinningLoaderWrapper = styled.div<{ $show?: boolean }>`
  height: 40px;
  width: 40px;
  padding: 0;
  display: ${({ $show }) => ($show ? 'block' : 'none')};
`;

export default SpinningLoaderWrapper;
