/* eslint-disable import/prefer-default-export */

import { css } from 'styled-components';
import { breakpoint } from './globals';

export const videos = css`
  .vodvideocontainer {
    width: 100%;
    padding-bottom: 0 !important;

    @media (${breakpoint.mdMin}) {
      width: 512px !important;
      height: 310px ! important;
    }
    
    @media(${breakpoint.lgMin}){
      width: 550px !important;
      height: 310px ! important;
    }
    
    @media(${breakpoint.hybridMin}){
      width: 698px !important;
      height: 392px ! important;
      margin-left: -20px !important;
    }


    @media (${breakpoint.xlMin}) {
      width: 800px !important;
      height: 480px !important;
      margin-left: -5px !important;
     
    }

    @media (${breakpoint.xxlMin}) {
      margin-left: -10px !important;
      width: 110% !important;
      height: 450px !important;
    
    }
  }
`;
