/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { Theme, getColor } from 'styles/theme';
import { arrowDown } from 'styles/icons';
import { font } from 'styles/globals';

export const details = css<{ theme: Theme }>`
  .wp-block-details {
    font-family: ${font.georgia};
    margin-bottom: 24px;

    & > summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      color: ${getColor('textPrimary')};
      padding: 8px 0;
      border-bottom: 1px solid ${getColor('borderSecondary')};

      &::-webkit-details-marker {
        display:none;
      }

      &, & > h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.5px;
        margin: 0;
      }

      &::after {
        content: "";
        ${arrowDown}
        border-color: ${getColor('borderBlack')};
        padding: 4px;
      }

      &:hover {
        cursor: pointer;
        color: ${getColor('textAccentPrimary')};
      }
    }

    & > *:not(summary) {
      margin-top: 0;
      margin-bottom: 24px;
    }

    &[open]{
      & > summary {
        margin-bottom: 16px;

        &:after {
          border-color: ${getColor('borderAccentPrimary')};
          transform: rotate(225deg);
        }
      }
    }
  }
`;
