/* eslint-disable import/prefer-default-export */
import { secondaryLink } from 'components/Globals/Base/Link/LinkStyles';
import { css } from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const monthlyArticle = css<{ theme: Theme }>`

    .monthly-article-widget {
      width: 100%;
      background-color: ${getColor('shadeBrandPrimary100')};
      padding: 32px;
      font-family: ${font.graphikCond};
      position: relative;

      @media (${breakpoint.mdMin}) {
        padding: 40px;
      }

      h2 {
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0.5;
        margin: 0 0 4px 0;

        @media (${breakpoint.mdMin}) {
          font-size: 40px;
          line-height: 48px;
        }
      }

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: ${getColor('textSecondary')}
      }

      ul {
        margin: 32px 0;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        column-gap: 8px;
        row-gap: 16px;

        li {
          margin: 0;
          list-style-type: none;
          position: relative;
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0.5px;

          a {
            ${secondaryLink}       
            padding-right: 8px;
          }
          
          &:not(:last-child) > a {
            @media (${breakpoint.mmMin}) {
              border-right: 1px solid ${getColor('borderSecondary')};
            }
          }
        }

        @media (${breakpoint.mdMin}) {
          margin: 32px 0 0 0; 
        }
      }

      &::after {
        content: url('/icons/RecommendsSVGLogo.svg');
        
        @media (${breakpoint.mdMin}) {
          position: absolute;
          top: 40px;
          right: 40px;
        }
      }
    }
`;
