/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { Theme, getColor } from 'styles/theme';
import { breakpoint, font } from 'styles/globals';

export const whyTrustUs = css<{ theme: Theme }>`
    .why-trust-us-widget {
        background: ${getColor('surfacePrimary')};
        padding: 16px;

        .wp-block-details[open] > summary {
            margin-bottom: 0px;
        }

        .wp-block-heading {
            font-family: ${font.graphikCompact};
            margin-bottom: 0px;
        }

        summary {
            border: none;

            h3:first-of-type {
                margin-bottom: 0px;
            }
        } 

        ul,ol {
            padding-inline-start: 0;
            

            li:before {
                content: url(/icons/CheckMarkLogo.svg);
                margin-top: 5px;
            }

            li {
                font-family: ${font.graphikCompact};
                font-size:  1rem;
                display: flex;
                flex-direction: row;
                list-style-type: none;
                gap: 0.5em;
                line-height: 1.5;
            }
        }

        *:last-child {
            padding-block-end: 0px;
            margin-block-end: 0px;
        }
    }

    @media (${breakpoint.mdMin}) {
        .why-trust-us-widget {
            padding: 24px;
        }
    }
`;
