/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { getColor, Theme } from 'styles/theme';
import { primaryBrandButtonSmall } from 'components/Globals/Base/Button/buttonStyles';

export const readMoreBox = css<{ theme: Theme }>`
  .read-more-box-widget {
    position: relative;

    .read-more-content .wp-block-group__inner-container > * {
      max-height: calc(1.5em * 10);
      overflow: hidden;
      position: relative;
    }

    &.overlay-5-visible-3 { 
      .read-more-content .wp-block-group__inner-container > * {
        max-height: calc(1.5em * 8);
        overflow: hidden;
        position: relative;
      }
    }

    &.overlay-5-visible-10 
      .read-more-content .wp-block-group__inner-container > * {
        max-height: calc(1.5em * 15);
        overflow: hidden;
        position: relative;
      }

    .read-more-content::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: calc(1.5em * 5);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, ${getColor('backgroundPrimary')} 60%);
    }
    
    .read-more-toggle-open, .read-more-toggle-close {
      ${primaryBrandButtonSmall};
      cursor: pointer;

      &::after {
        display: none;
      }
    }

    .read-more-toggle-close {
      display: none;
    }

    .read-more-toggle-open {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 24px auto;
      width: 96px;
    }
  }

  .read-more-box-widget.open {
    .wp-block-group__inner-container .read-more-content .wp-block-group__inner-container > * {
        max-height: 100%;
    }

    .read-more-content::after {
        display: none;
    }

    .read-more-toggle-close {
        display: table;
        margin: 48px auto;
    }

    .read-more-toggle-open {
        display: none;
    }
  }
`;
