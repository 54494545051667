/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { color, font } from './globals';

export const stories = css`
  h3:last-of-type {
    color: ${color.primaryBlack};
    display: block;
    font-family: ${font.saolText};
    font-size: 24px;
    font-weight: 700;
    letter-spacing: .035rem;
    line-height: 1;
    margin: 0px 0px 16px;

    em {
      font-style: normal;
    }
  }
`;
