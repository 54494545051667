import { css } from 'styled-components';
import { color, font, breakpoint } from './globals';

export const blockQuotes = css`
  .wp-block-quote {
    margin: 0px;
    margin-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;

    @media (${breakpoint.xlMin}) {
      padding-left: 0;
      padding-right: 0;
    }

    blockquote {
      margin: 0px;
    }
    
    p {
      position: relative;
      color: ${color.primaryRed};
      font-family: ${font.saolText};
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      margin: 0px;

      @media(${breakpoint.mdMin}) {
        font-size: 34px;
        line-height: 34px;
        letter-spacing: 0.256px;
      }

      @media (${breakpoint.xlMin}) {
        font-size: 48px;
        line-height: 48px;
      }
    }

    p:before {
      content: ",,";
      display: inline-block;
      padding-top: 2px;
      transform: rotate(0.5turn);

      @media (${breakpoint.mdMin}) {
        padding-top: 4px;
      }
    }

    p:after {
      content: ",,";
      position: absolute;
      bottom: 12px;

      @media (${breakpoint.mdMin}) {
        bottom: 16px;
      }

      @media (${breakpoint.xlMin}) {
        bottom: 24px;
      }
    }

    cite {
      font-family: ${font.graphikCond};
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 18px;
      text-transform: uppercase;

      @media(${breakpoint.mdMin}) {
        font-size: 16px;
      }
    }
  }
`;

export const pullQuotes = css`
  .wp-block-pullquote {
    margin: 0px;
  
    blockquote {
      margin: 0px;
    }
  
    p {
      color: ${color.primaryRed};
      font-family: ${font.saolText};
      font-size: 48px;
      font-weight: 600;
      line-height: 1;
      margin: 0px 0px 4px 0px;
    }
  
    cite {
      font-family: ${font.graphikCond};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
`;

export const longQuotes = css`
  .wp-block-longquote, .wp-block-custom-block-longquote {
    border-left: 16px solid ${color.lightBlue};
    margin: 16px auto;
    padding-left: 24px;

    p {
      margin: 0px 0px 16px;
    }

    cite {
      font-style: normal;
    }
  }
`;
