/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { Theme, getBodyStyle, getColor } from 'styles/theme';
import { breakpoint, font } from 'styles/globals';

export const proTipBox = css<{ theme: Theme }>`
  .pro-tip-box-widget {
    padding: 24px;
    margin: 24px 0;
    position: relative;
    background-color: ${getColor('backgroundSecondary')};

    p {
        ${getBodyStyle('graphikCompact', { default: 'default' })};
        position: relative;
        margin-block: 8px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
  }

  h3.pro-tip-box-heading {
    display: flex;
    gap: 16px;
    font-family: ${font.graphikCond};
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.5px;
    position: relative;
    &::before {
      content: url('/icons/ProTipBoxLightbulb.svg');
    }
  }

  @media (${breakpoint.mdMin}) {
    h3.pro-tip-box-heading{
      font-size: 24px;
      &::before {
        transform: scale(1.2);
        padding-top: 5px;
      }
    }
  }

`;
