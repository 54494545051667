/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { getColor, Theme } from 'styles/theme';
import { breakpoint } from 'styles/globals';

export const prosCons = css<{ theme: Theme }>`
  .card-accordion-panel .pros_cons {
    padding: 0;
    box-shadow: unset;
  }

  .pros_cons {
    display: flex;
    flex-wrap: wrap;
    padding-block: 24px;
    padding-inline: 40px;
    gap: 40px;
    box-shadow: 4px 4px 20px 0px ${getColor('borderPrimary')};

    @media (${breakpoint.mmMin}) {
      flex-wrap: nowrap;
    }

    div {
      width: 100%;

      &:nth-child(1) h4::before, &:nth-child(1) h3::before {
        content: url("/icons/ProIcon.svg");
      }

      &:nth-child(2) h4::before, &:nth-child(2) h3::before {
        content: url("/icons/ConIcon.svg");
      }

      h4::before, h3::before{
        margin-right: 8px;
        padding-top: 4px;
      }

      h4, h3 {
        font-size: 20px;
        display: flex;
        align-items: center;
        margin: 0;
      }

      ul {
        margin: 24px 0 0 28px;
      }

      li {
        margin-bottom: 16px;
      }

      li:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
